import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import style from './DepreciationFactorManagementTable.module.scss';
import InitTable from '../Table/Table';
import Loader from '../Loader/Loader';
import _ from 'lodash';
import { getDepreciationSchedule, getErrorMessage } from '../../services';
import { DepreciationScheduleFactor, IToastMessage } from '../../interface';
import { InputType } from '../Table/components/Editor/Editor';

const { Table, Column } = InitTable<DepreciationScheduleFactor>();

interface IUpdateSelectedFactorsForm {
  age: number;
  factorValue: number;
}

interface Props {
  className?: string;
  depreciationScheduleId?: number | null;
  updateUnsavedFactorsList: (newValues: DepreciationScheduleFactor[]) => void;
}

const initialValues = {
  age: 0,
  factorValue: 0
} as IUpdateSelectedFactorsForm;

function DepreciationFactorManagementTable({
  className,
  depreciationScheduleId,
  updateUnsavedFactorsList,
}: Props) {
  const [fetchingFactors, setFetchingFactors] = useState(false);
  const [depreciationFactors, setDepreciationFactors] = useState<DepreciationScheduleFactor[]>([]);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage({
      message,
      visible: true,
      type: 'error',
    });
  }
  
  useEffect(() => {
    if (depreciationScheduleId) {
      setFetchingFactors(true);
      getDepreciationSchedule(depreciationScheduleId)
        .then((res) => {
          if (res.data) {
            setDepreciationFactors(res.data.factors);
            setFetchingFactors(false);
          }
        })
        .catch((e) => {
          showErrorMessage(getErrorMessage(e));
          setFetchingFactors(false);
        })
    }
  }, [depreciationScheduleId]);

  const saveChanges = (newValue: DepreciationScheduleFactor) => {
    const factors = _.cloneDeep(depreciationFactors)
    const matchIndex = _.findIndex(factors, {
      age: newValue.age,
    });

    if (matchIndex >= 0) {
        factors[matchIndex] = {
        ...factors[matchIndex],
        value: newValue.value,
      };
    }
    else {
      factors.push(newValue);
    }
    setDepreciationFactors(factors);

    updateUnsavedFactorsList([newValue]);
  };

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'DepreciationFactorManagement d-flex flex-column'
      )
    }>
      <Table
        id='AssessorManagementDetailsEditTable'
        rows={depreciationFactors}
        paginate
        sortable
        totalRows={depreciationFactors?.length}
        loading={false}
      >
        <Column
          label='Age'
          prop='age'
        />

        <Column
          label='Value'
          prop='value'
          editable={true}
          editor={{
            type: InputType.Text,
            label: 'Value',
          }}
          onChange={(newValue, row) => {
            saveChanges({
              ...row,
              value: newValue,
            })
          }}
        />
      </Table>

      <Loader isOpen={
        fetchingFactors
      } />
    </div>
  );
}

export default DepreciationFactorManagementTable;