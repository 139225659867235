import classNames from 'classnames';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import style from './DepreciationCollectionManagementTable.module.scss';
import InitTable, { Query } from '../Table/Table';
import { InputType } from '../Table/components/Editor/Editor';
import { Link, useNavigate } from 'react-router-dom';
import { deleteDepreciationScheduleCollection, getDepreciationScheduleCollections, getErrorMessage, getSystemTaxYears } from '../../services';
import { useAppSelector } from '../../redux/store';
import { DepreciationScheduleCollection, IToastMessage, SelectOption, State } from '../../interface';
import _ from 'lodash';
import ConfirmModal from '../ConfirmModal/ConfirmModal';


const { Table, Column } = InitTable<DepreciationScheduleCollection>();

interface Props {
  className?: string;
  setToastMessage?: React.Dispatch<React.SetStateAction<IToastMessage | undefined>>;
}


function DepreciationCollectionManagementTable({
  className,
  setToastMessage
}: Props) {
  const stateOptions = useAppSelector(state => (state.states ?? []).map((o: State) => ({
    value: o.id,
    label: o.abbreviation,
    name: o.abbreviation,
  })));
  const [fetchingAllTaxYears, setFetchingAllTaxYears] = useState(false);
  const [activeTaxYears, setActiveTaxYears] = useState<SelectOption[]>([]);
  const [currentTaxYear, setCurrentTaxYear] = useState<number | null>(null);
  const [depreciationCollections, setDepreciationCollections] = useState<DepreciationScheduleCollection[]>([])

  useEffect(() => {
    setFetchingAllTaxYears(true);
    getSystemTaxYears()
      .then((res) => {
        setFetchingAllTaxYears(false);
        const allActiveTaxYears = _.orderBy(
          res.data,
          ['taxYear'],
          ['desc'],
        );

        setActiveTaxYears(allActiveTaxYears.map(taxYear => { 
          return { 
            item: taxYear, 
            label: taxYear.taxYear.toString(), 
            name: taxYear.taxYear.toString(), 
            value: taxYear.taxYear 
          } as SelectOption; 
        }));

        let currentTaxYearIndex = _.findIndex(allActiveTaxYears, {
          isCurrent: true,
        });
        if (currentTaxYearIndex < 0) {
          currentTaxYearIndex = 0;
        }
        setCurrentTaxYear(allActiveTaxYears[currentTaxYearIndex].taxYear);
      })
      .catch(() => {
        setFetchingAllTaxYears(false);
      });
  }, []);
  
  //Pagination
  const [total, setTotal] = useState<number>(0);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<DepreciationScheduleCollection | null>(null)
  const [fetchingData, setFetchingData] = useState(false);
  const [deletingDepreciationCollection, setDeletingDepreciationCollection] = useState(false);
  const previousQuery = useRef<Query | undefined>()
  const navigate = useNavigate();
  const showSuccess = () => {
    window.scrollTo(0, 0);
    setToastMessage?.({
      message: 'Depreciation Collection deleted successfully.',
      visible: true,
      type: 'success',
    });
  }
  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage?.({
      message,
      visible: true,
      type: 'error',
    });
  }

  const fetchData = useCallback((query?: Query) => {
    previousQuery.current = query;
    setFetchingData(true);
    getDepreciationScheduleCollections({
      stateIds: (query?.filter?.stateIds as number[])?.join(','),
      text: query?.search,
      taxYear: (query?.filter?.taxYear as number) ?? currentTaxYear,
      sortBy: _.last(_.keys(query?.order)),
      sortDirection: _.last(_.values(query?.order)),
      page: query?.pagination?.page ?? 1,
      perPage: query?.pagination?.pageSize ?? 10,
    })
      .then((res) => {
        setDepreciationCollections(res.data.items);
        setTotal(res.data.total);
        setFetchingData(false);
      })
      .catch(() => {
        setFetchingData(false);
      });

  }, []);

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'FormManagementSearch d-flex flex-column'
      )
    }>
      <Table
        id='DepreciationCollectionManagementTable'
        rows={depreciationCollections}
        onQueryChanged={fetchData}
        paginate
        sortable
        totalRows={total}
        loading={fetchingData || fetchingAllTaxYears}
        searchable='Search Depreciation Collection...'
        dynamicColumns
        showAddButton='Add Depreciation Collection'
        onAddButtonClick={() => navigate('/admin/depreciation-collection-management/new')}
      >
        {/* Collection Name */}
        <Column
          label='Collection Name'
          id='name'
        >
          {
            (row: DepreciationScheduleCollection) => (
              <span><strong>{row.name}</strong></span>
            )
          }
        </Column>
        
        {/* Collection State */}
        <Column
          label='Collection State'
          id="stateIds"
          prop="state.abbreviation"
          sortKey='state.abbreviation'
          filterable={{
            type: InputType.Select,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            options: stateOptions,
            multi: true,
            searchable: true,
          }}
        />

        {/* Tax Year */}
        <Column
          label='Tax Year'
          prop='taxYear'
          sortKey='taxYear'
          filterable={{
            type: InputType.Select,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            options: activeTaxYears,
            multi: false,
            searchable: true,
          }}
        />

        {/* Actions column  */}
        <Column
          id='actions'
          label='Actions'
          sortable={false}
        >
          {(data: DepreciationScheduleCollection) => (
            <div className={classNames(style['operate'], 'd-flex')}>
              <Link
                to={`/admin/depreciation-collection-management/${data.id}/edit`}
                className='edit'
              />
              <button
                role='button'
                className='delete'
                onClick={() => {
                  setConfirmDeleteOpen(data);
                }}
              ></button>
            </div>
          )}
        </Column>
      </Table>

      <ConfirmModal
        isOpen={!!confirmDeleteOpen}
        onRequestClose={() => setConfirmDeleteOpen(null)}
        title="Confirmation"
        content="Are you sure you want to delete this Depreciation Collection?"
        confirming={deletingDepreciationCollection}
        onConfirm={() => {
          setDeletingDepreciationCollection(true);
          deleteDepreciationScheduleCollection(confirmDeleteOpen?.id ?? 0)
            .then(() => {
              setConfirmDeleteOpen(null);
              showSuccess();
              fetchData(previousQuery.current);
              setDeletingDepreciationCollection(false);
            })
            .catch((e) => {
              setConfirmDeleteOpen(null);
              showErrorMessage(getErrorMessage(e));
              setDeletingDepreciationCollection(false);
            });
        }}
      />
    </div>
  );
}

export default DepreciationCollectionManagementTable;