import React from "react";
import { Outlet } from "react-router-dom";
import Selection from "../../components/Selection/Selection";
import style from "./Admin.module.scss";

function Admin() {
  return (
    <div className={style["admin-container"]}>
      <div className={style["admin-content"]}>
        <h2>Admin</h2>
        <div className={style["selection-container"]}>
          <Selection
            title="Admin Functions"
            selectionList={[
              {name: "Assessor Management", to: "/admin/assessor-management", startsWith: "/admin/assessor-management"},
              {name: "Form Management", to: "/admin/form-management"},
              {name: "Tool Tip", to: "/admin"},
              {name: "Help Content", to: "/admin/help-content"},
              {name: "Setup New Account", to: "/admin/new-account"},
              {name: "Purchased Returns", to: "/admin/purchase-returns"},
              {name: "Maintenance Notifications", to: "/admin/maintenance-notifications"},
              {name: "Depreciation Management", to: "/admin/depreciation-collection-management"},
            ]}
          />
        </div>
      </div>

      <Outlet />
    </div>
  );
}

export default Admin;
